const validationMessage = $("#validation-message")
const requiredFields = $(".required");
const form = $('form')
const gradesTaught = $('#grades-taught')
const charterSplit = $('#charters-split')
const scheduledDays = $('#scheduled_days')

function form_errors() {
    let errors = ''
    let error = ''
    if (!validate_charter_percentage()) {
        error = ' Charter percentages must add to 100'
        add_error(charterSplit, error)
        errors += error

    }
    if (!validate_days_of_week()) {
        error = ' Please select scheduled days'
        add_error(scheduledDays, error)
        errors += error
    }
    if (!validate_grades_taught()) {
        error = ' Please select grades taught'
        add_error(gradesTaught, error)
        errors += error
    }
    if (required_field_errors() !== '') {
        error = ' Please fill in required fields'
        errors += error
    }
    return errors
}

function required_field_errors() {
    let errors = ''
    requiredFields.each(
        function () {
            if (invalid_field($(this)) && $(this).is(":visible")) {
                errors = ' Cannot Be Blank!';
            }
        }
    );
    return errors;
}

function invalid_field(field) {
    let error = null;
    if (field.val() === '') {
        error = 'Cannot be Blank';
        add_error(field, error)
    }
    return error;
}

function add_error(field, error) {
    const error_field_id = field.attr('name') + '_error'
    field.addClass("error");
    field.css("background-color", "#f2dede");
    field.css("color", '#8B0000');
    const error_html = '<p id="' + error_field_id + '" style="color:#8B0000;" >' + error + '</p>'
    field.after(error_html)
}

function clear_errors() {
    $(".error").each(function () {
        let error_field = $(this).attr('name') + '_error'
        $(this).removeClass("error");
        $(this).css("background-color", "#ffffff");
        $(this).css("color", "black");
        $('#' + error_field).hide()
    })
}

function submit_form(e) {
    clear_errors();
    const submit = e.target.name

    const errors = form_errors()
    if (errors === '') {
        validationMessage.hide();
        form.append(`<input type="hidden" name="${submit}" value="submit" />`)
        form.submit();
    } else {
        e.target.disabled = false
        validationMessage.text('There are errors preventing this form from being saved. \n' + errors)
        validationMessage.show();
    }
}

function validate_charter_percentage() {
    if (!charterSplit.is(":visible")) {
        return true
    }
    const percentages = $('.charterSplit').map(function () {
        return this.value;
    }).get()
    const total = percentages.reduce((prev, curr) => prev + parseInt(curr), 0);
    return total === 100
}

function validate_days_of_week() {
    if (!scheduledDays.is(":visible")) {
        return true
    }
    const checkboxes = Array.from($(`[id*="_scheduled_days"]`))
    return checkboxes.some(checkbox => checkbox.checked)
}

function validate_grades_taught() {
    if (!gradesTaught.is(":visible")) {
        return true
    }
    const checkboxes = Array.from($(`[id*="_grades_taught"]`))
    return checkboxes.some(checkbox => checkbox.checked)
}

export {submit_form};